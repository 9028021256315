import React from 'react';

import './logo.css';

const Logo = () => {
    return(
        <div className="logo">
            MP
        </div>
    )
}

export default Logo;